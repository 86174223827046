'use strict'

const redirects = {

    isRequired: () => {
        throw new Error('Session es requerido')
    },

    redirectTo: (location = null) => {

        return {
            redirect: {
                permanent: false,
                destination: location ? location : '/login'
            }
        }
    },

    redirectToNotFound: () => {

        return {
            redirect: {
                permanent: false,
                destination: location ? location : '/404'
            }
        }
    },

    guest: () => {
        return redirects.redirectTo('/home')
    },

    auth: () => {
        return redirects.redirectTo('/login')
    },

    toEvent: (status, url, url2 = null, hasDiploma = null) => {
        return redirects.redirectTo(`/login/?status=${status}&url=${url}${ url2 ? `&url2=${url2}` : '' }${ hasDiploma ? `&hasDiploma=${hasDiploma}` : '' }`)
    },

    protected: () => {
        return redirects.redirectTo('prohibido')
    },

    chat: (session, role) => {
        //console.log(  Number(session.user.image.role) === role  )
        return ( Number(session.user.image.role) === role || Number(session.user.image.role) === 5 ) ? true : false
    },

    isAdmin: (session) => {
       
        return ( Number(session.user.image.role) >= 5 ) ? true : false
    },


    canVisit: (session) => {
        return (session.user.image.license_verified === 'null' || session.user.image.license_verified) === 'false' ? false : true
    }
    

}

export default redirects